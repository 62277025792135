<script setup lang="ts">
import { onMounted, ref, onUnmounted } from "vue"
import { useAccountStore, useLPStore } from "@/store"
import ContractLoanSummary from "@/components/loan-processing/ContractLoanSummary.vue"
import ContractConditions from "@/components/loan-processing/ContractConditions.vue"
import ContractErrorDialog from "@/components/loan-processing/ContractErrorDialog.vue"
import { ContractSignatureParams, postSignContract } from "@/api/secure/lp-service"
import { useRouter } from "vue-router/composables"

const { fullName, cpf } = useAccountStore()
const { unsignedContract } = useLPStore()
const nameField = ref<string>("")
const cpfField = ref<string>("")
const cpfFieldError = ref<boolean>(false)
const cpfMismatch = ref<boolean>(false)

const scrolled = ref<boolean>(false)
const conditionsCheckError = ref<boolean>(false)
const isConditionsChecked = ref<boolean>(false)
const showReadError = ref<boolean>(false)
const sectionACheckError = ref<boolean>(false)
const isAChecked = ref<boolean>(false)
const sectionBCheckError = ref<boolean>(false)
const isBChecked = ref<boolean>(false)

const isMobile = ref<boolean>(false)
const submitButtonText = ref<string>("ASSINAR CONTRATO")
const showErrorDialog = ref<boolean>(false)
const router = useRouter()

const onScroll = function(e : any) {
  const { scrollTop, offsetHeight, scrollHeight } = e.target
  if ((scrollTop + offsetHeight) >= scrollHeight) {
    scrolled.value = true
    showReadError.value = false
    const element = document.getElementById("arrow-span") as HTMLElement
    element.style.visibility = "hidden"
  }
}

const checkConditionsBox = function(event: Event) {
  const target = event.target as HTMLInputElement
  if (isConditionsChecked.value) { // uncheck if clicked again
    isConditionsChecked.value = false
    target.checked = false
    return
  }

  if (!scrolled.value) {
    target.checked = false
    isConditionsChecked.value = false
    conditionsCheckError.value = true
    showReadError.value = true
    const element = document.getElementById("arrow-span") as HTMLElement
    element.style.color = "#a94442"
  } else {
    target.checked = true
    isConditionsChecked.value = true
  }
}

const checkABox = function(event: Event) {
  const target = event.target as HTMLInputElement
  if (isAChecked.value) { // uncheck if clicked again
    isAChecked.value = false
    target.checked = false
    return
  }
  target.checked = true
  isAChecked.value = true
}

const checkBBox = function(event: Event) {
  const target = event.target as HTMLInputElement
  if (isBChecked.value) { // uncheck if clicked again
    isBChecked.value = false
    target.checked = false
    return
  }
  target.checked = true
  isBChecked.value = true
}

const doesCPFMatch = function() {
  const regex = /[^\w]/g
  const strippedCPF = cpfField.value.replace(regex, "")
  if (strippedCPF != cpf.value.toString()) {
    return false
  }
  return true
}

const submitContract = async function() {
  if (!isConditionsChecked.value && !isMobile.value) {
    conditionsCheckError.value = true
    return
  } else if ((isConditionsChecked.value || isMobile.value) && !isAChecked.value) {
    sectionACheckError.value = true
    return
  } else if ((isConditionsChecked.value || isMobile.value) && isAChecked.value && !isBChecked.value) {
    sectionBCheckError.value = true
    return
  } else if ((isConditionsChecked.value || isMobile.value) && isAChecked.value && isBChecked.value && cpfField.value == "") {
    cpfFieldError.value = true
    cpfMismatch.value = false
    return
  } else if ((isConditionsChecked.value || isMobile.value) && isAChecked.value && isBChecked.value && !doesCPFMatch()) {
    cpfFieldError.value = true
    cpfMismatch.value = true
    return
  } else {
    const button = document.getElementById("submit") as HTMLElement
    button.classList.add("disable-button")
    submitButtonText.value = "CARREGANDO..."
    const contractID = unsignedContract.value.loan_agreement.contract.contract_id ? unsignedContract.value.loan_agreement.contract.contract_id : ""
    const payload: ContractSignatureParams = {
      agreement_a: "1",
      agreement_c: "1",
      cpf: cpf.value.toString(),
      full_name: fullName.value,
      read_contract: "1",
      locale: unsignedContract.value.loan_agreement.contract.locale.current,
      loan_application_id: unsignedContract.value.loan_agreement.loan_application_id,
      contract_id: contractID
    }
    try {
      await postSignContract(unsignedContract.value.loan_agreement.account_id.toString(), unsignedContract.value.loan_agreement.loan_number, payload)
      router.push("/")
    } catch(e) {
      submitButtonText.value = "ASSINAR CONTRATO"
      showErrorDialog.value = true
      document.documentElement.style.overflow = "hidden"
    }
  }
}

const disappearFlashError = function() {
  if (conditionsCheckError.value || sectionACheckError.value || sectionBCheckError.value || cpfFieldError.value) {
    conditionsCheckError.value = false
    sectionACheckError.value = false
    sectionBCheckError.value = false
    cpfFieldError.value = false
  }
}

const refreshPage = function() {
  location.reload()
}

onMounted(() => {
  document.addEventListener("click", disappearFlashError, { capture: true })
  if (window.innerWidth <= 600) {
    isMobile.value = true
  }
  nameField.value = fullName.value
})

onUnmounted(() => {
  document.removeEventListener("click", disappearFlashError, { capture: true })
})

defineExpose({
  onScroll,
  checkConditionsBox,
  isConditionsChecked,
  scrolled,
  conditionsCheckError,
  showReadError,
  checkABox,
  isAChecked,
  checkBBox,
  isBChecked,
  submitContract,
  isMobile,
  nameField,
  cpfField,
  cpfFieldError,
  cpfMismatch,
  sectionACheckError,
  sectionBCheckError,
  disappearFlashError
})
</script>
<template>
  <div id="contract-container">
    <p class="text-center bigger-text"><strong>{{ fullName }}</strong>{{ $t("pleaseReviewAndSign") }}</p>

    <contract-loan-summary :unsigned-contract="unsignedContract" />

    <div v-html="unsignedContract.loan_agreement.contract.body" />

    <div id="section-seven">
      <div v-if="!isMobile" class="scrollable-content" @scroll="onScroll">
        <contract-conditions :is-mobile="isMobile" />
      </div>
      <div v-if="isMobile" class="conditions-mobile">
        <contract-conditions :is-mobile="isMobile" />
      </div>
    </div>
    <div v-if="!isMobile" id="scrollbox-arrow">
      <span id="arrow-span">↓</span>
    </div>

    <div class="binding-container"><p>{{ $t("issuerAndCreditorBinding") }}</p></div>

    <div v-show="!isMobile" class="check-and-text">
      <label class="checkbox-container">
        <input v-model="isConditionsChecked" type="checkbox" @click="checkConditionsBox" />
        <span class="checkmark" />
      </label>
      <div class="relative-container">
        <div v-show="conditionsCheckError" id="conditions-checkbox-error">
          <span class="arrow-up" style="" />
          <img aria-hidden="true" src="~@/assets/images/error-exclamation.png" width="20" height="20" alt="error icon" />
          <p>{{ showReadError ? $t("readContractBeforeAccept") : $t("checkboxError") }}</p>
        </div>
      </div>
      <p>{{ $t("iUnderstandAndAgree") }}</p>
    </div>

    <div id="section-a">
      <div class="green-panel">
        <p class="heading">(A)</p>
      </div>
      <label class="checkbox-container">
        <input v-model="isAChecked" type="checkbox" @click="checkABox" />
        <span class="checkmark" />
      </label>
      <div class="relative-container">
        <div v-show="sectionACheckError" id="a-checkbox-error">
          <span class="arrow-up" style="" />
          <img aria-hidden="true" src="~@/assets/images/error-exclamation.png" width="20" height="20" alt="error icon" />
          <p>{{ $t("checkboxError") }}</p>
        </div>
      </div>
      <p class="move-left"><strong><u>{{ $t("iAgree") }}</u></strong>{{ $t("agreeText") }}</p>
      <p class="move-left"><strong><u>{{ $t("iDeclare") }}</u></strong>{{ $t("declareTextBeginning") }}<strong>{{ $t("ppe") }}</strong>{{ $t("declareTextEnd") }}</p>
    </div>

    <div id="section-b">
      <div class="green-panel">
        <p class="heading">(B)</p>
      </div>
      <label class="checkbox-container">
        <input v-model="isBChecked" type="checkbox" @click="checkBBox" />
        <span class="checkmark" />
      </label>
      <div class="relative-container">
        <div v-show="sectionBCheckError" id="b-checkbox-error">
          <span class="arrow-up" style="" />
          <img aria-hidden="true" src="~@/assets/images/error-exclamation.png" width="20" height="20" alt="error icon" />
          <p>{{ $t("checkboxError") }}</p>
        </div>
      </div>
      <p class="move-left"><strong><u>{{ $t("iAuthorize") }}</u></strong>{{ $t("authorizeTextBeginning") }}<strong>{{ $t("afinzSorocred") }}</strong>{{ $t("authorizeTextMiddle") }}<strong>{{ $t("lh1003") }}</strong>{{ $t("authorizeTextEnd") }}</p>
    </div>

    <div id="signature-block">
      <div class="form-group">
        <label for="full_name" class="form-label required">{{ $t("signatureFullName") }}</label>
        <input id="full_name" v-model="nameField" type="text" class="form-input" readonly />
      </div>
      <div class="form-group">
        <label for="cpf" class="form-label required">{{ $t("cpf") }}</label>
        <input id="cpf" v-model="cpfField" type="text" class="form-input" />
        <div class="relative-container">
          <div v-show="cpfFieldError" id="cpf-field-error">
            <span class="arrow-up" style="" />
            <img aria-hidden="true" src="~@/assets/images/error-exclamation.png" width="20" height="20" alt="error icon" />
            <p>{{ cpfMismatch ? $t("cpfInvalid") : $t("fillFieldError") }}</p>
          </div>
        </div>
      </div>

      <div class="button-container">
        <button id="submit" class="submit-button" type="button" @click="submitContract">
          <span>{{ submitButtonText }}</span>
        </button>
      </div>
    </div>
    <contract-error-dialog class="error-box" :show-dialog="showErrorDialog" @close="refreshPage" />
  </div>
</template>
<style lang="scss"> // note: style cannot be scoped because of v-html, wrap everything in #contract-container
#contract-container {
  .bold {
    font-weight: bold;
  }

  span {
    font-size: 15px;
    line-height: 25px;
    color: $simplic-dark-text;

    @media (max-width: 500px) {
      font-size: 14px;
      line-height: 22px;
    }
  }

  h5 {
    font-size: 17px;
    font-weight: normal;
  }

  .panel-title {
    background-color: $simplic-yellow;
    border-radius: 6px;
    font-size: 0.75em;
    padding-left: 0.5em;
    color: $simplic-dark-text;
    font-size: 18px;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    line-height: 36px;
    text-align: left;
  }

  .panel-heading {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .panel-body {
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0.5em;
    margin-bottom: 1em;
    justify-content: space-between;

    @media (max-width: 500px) {
      display: block;
      .col-xs-12 {
        width: 100%;
      }
    }

    .col-xs-12 {
      width: 50%;
    }
  }

  .contract-body {
    margin: 0 auto;
    padding: 0;
  }

  .col-lg-6 {
    max-width: 100%;
  }

  .row {
    display: block;
  }

  .preamble-header {
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0.5em;
    margin-bottom: 1em;
    margin-top: 1em;

    @media (max-width: 500px) {
      display: block;
    }

    span {
      font-size: 17px;
      padding-right: 32px;

      @media (max-width: 500px) {
        font-size: 15px;
      }
    }

    .bold {
      padding-right: 0;
    }

    .date_of_issue, .bank_credit_card_number {
      padding-bottom: 10px;
    }
  }

  .green-panel {
    background-color: $simplic-green;
    font-size: 0.75em;
    margin-top: 1em;
    padding-left: 0.5em;
    border-radius: 6px;
    color: $simplic-white;
    font-size: 18px;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    line-height: 36px;
    text-align: left;
  }

  .scrollable-content {
    background: $simplic-background;
    height: 200px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-bottom: 20px;
    padding: 10px 20px;
    border: 2px solid $simplic-dark-gray;
  }

  .conditions-mobile{
    border: 2px solid $simplic-dark-gray;
    background: $simplic-background;
  }

  #scrollbox-arrow {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-top: -64px;

    span {
      font-size: 56px;
      color: $simplic-yellow;
      font-weight: bold;
    }
  }


  #section-seven {
    .no-margin {
      margin-bottom: 0px;
    }
  }

  .binding-container {
    background-color: #fafafa;
    padding: 10px;
    border: solid 1px $simplic-background;
    border-radius: 5px;
    margin-top: 35px;

    p {
      padding: 10px;
      margin: 0 auto;
    }
  }

  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 10px;
      left: 0;
      height: 30px;
      width: 30px;
      background-color: $simplic-white;
      border: 2px solid $simplic-green;
      border-radius: 4px;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
      left: 10px;
      top: 5px;
      width: 7px;
      height: 13px;
      border: 3px solid $simplic-green;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    input:checked ~ .checkmark:after {
      display: block;
    }
  }

  .check-and-text {
    background-color: $simplic-background;
    padding: 9px;
    padding-left: 20px;
    margin-top: 20px;
    border-radius: 5px;

    p {
      padding-left: 3em;
    }
  }

  #section-a, #section-b {
    .move-left {
      padding-left: 3em;
    }
  }

  #signature-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 915px) {
      display: block;
    }

    .form-group {
      display: flex;
      flex-direction: column;
      margin-right: 20px;


      .form-label {
        display: block;
        margin-bottom: 2px;
        font-size: 15px;

        @media (max-width: 915px) {
          font-size: 13px;
          margin-top: 5px;
        }
      }

      .form-input {
        color: $simplic-dark-gray;
        background: #fafafa;
        border: solid 1px $simplic-light-gray;
        height: 40px;
        border-radius: 5px;
        padding: 10px;

        &:focus {
          background: white;
          border: solid 1px $simplic-yellow;
          color: $simplic-dark-text;
          box-shadow: 0 0 5px transparentize($simplic-yellow, 0.5);
          outline: 0;
        }

        @media (max-width: 915px) {
          width: 100%;
        }
      }
    }

    .button-container {
      width: 40%;
      text-align: left;
      padding-top: 13px;
      padding-bottom: 10px;

      .submit-button {
        background-color: $simplic-green;
        padding: 10px;
        width: 100%;
        border-radius: 5px;
        margin-top: 21px;

        span {
          color: $simplic-white;
          font-size: 0.85em;
          font-weight: bold;
          letter-spacing: 2px;
        }
      }

      .disable-button {
        pointer-events: none;
        background-color: $simplic-disabled-button-background;

        span {
          color: $simplic-disabled-button-text;
        }
      }

      @media (max-width: 915px) {
        width: 100%;
      }
    }
  }

  .relative-container {
    position: relative;
  }

  #conditions-checkbox-error, #a-checkbox-error, #b-checkbox-error, #cpf-field-error  {
      border: 1px solid #ccc;
      border-radius: 3px;
      width: 300px;
      padding: 10px;
      margin: 15px auto 0;
      position: absolute;
      bottom: -79px;
      left: -4px;
      background: #fff;
      display: flex;
      align-items: center;
      z-index: 100;

      p {
        font-size: 12px;
        margin: 0;
        padding-left: 1px;
      }

      img {
        margin-right: 6px;
      }
  }
  #conditions-checkbox-error::before, #conditions-checkbox-error::after,
  #a-checkbox-error::before, #a-checkbox-error::after,
  #b-checkbox-error::before, #b-checkbox-error::after,
  #cpf-field-error::before, #cpf-field-error::after {
      content: "";
      display: block;
      position: absolute;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      right: 270px;
  }
  #conditions-checkbox-error::before, #a-checkbox-error::before,
  #b-checkbox-error::before, #cpf-field-error::before {
      border-color: #ccc transparent;
      top: -10px;
      right: 270px;
  }
  #conditions-checkbox-error::after, #a-checkbox-error::after,
  #b-checkbox-error::after, #cpf-field-error::after {
      content: "";
      display: block;
      position: absolute;
      border-style: solid;
      border-color: #fff transparent;
      border-width: 0 10px 10px 10px;
      top: -9px;
  }
  #a-checkbox-error {
    bottom: -91px;
    left: -4px;
  }
  #b-checkbox-error {
    bottom: -91px;
    left: -4px;
  }
  #cpf-field-error {
    bottom: -51px;
    left: 17px;
    z-index: 100;
  }

  .pdf, h1, h2, .pdf_html_contract, .upcase {
    display: none;
  }

  #checkboxes {
    display: none;
  }

  .error-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
  }
}
</style>
<i18n>
  {
    "pt": {
      "pleaseReviewAndSign": ", por favor revise as informações abaixo e assine o contrato:",
      "issuerAndCreditorBinding": "E por estarem justas e acordadas, o EMITENTE emite a presente CCB eletronicamente em caráter irrevogável e irretratável, sendo apenas a via registrada negociável, obrigando EMITENTE, CREDOR e seus eventuais sucessores a qualquer título.",
      "iUnderstandAndAgree": "Li, compreendi e concordo com o contrato",
      "iAgree": "CONCORDO",
      "agreeText": " expressamente com o inteiro teor da presente Cédula de Crédito Bancário, ficando desde já obrigado e sujeito aos direitos e obrigações oriundos deste instrumento, em especial, mas não se limitando ao pagamento do Empréstimo nas condições nele convencionadas.",
      "iDeclare": "DECLARO",
      "declareTextBeginning": ", ainda, não ser ou ter como meus representantes, familiares ou outras pessoas de meu relacionamento próximo qualquer ",
      "ppe": "PESSOA POLITICAMENTE EXPOSTA (PPE)",
      "declareTextEnd": " (pessoas que desempenhem ou desempenharam, nos últimos cinco anos, no Brasil ou no exterior, cargos, empregos e/ou funções públicas relevantes). Conforme definido pela Circular nº 3.461 de 24.07.2009 do Banco Central do Brasil, as instituições financeiras devem observar procedimentos a respeito das movimentações financeiras das chamadas PPE.",
      "iAuthorize": "AUTORIZO",
      "authorizeTextBeginning": " expressamente a ",
      "afinzSorocred": "AFINZ (Sorocred)",
      "authorizeTextMiddle": " a divulgar, compartilhar e trocar as suas informações com a LH1003 Serviços de Dados Ltda. ou qualquer outro terceiro a ela ligado ou relacionado, para fins de registro, análise de risco de crédito e qualquer outra finalidade, incluindo, mas não limitado a comercialização de produtos da ",
      "lh1003": "LH1003",
      "authorizeTextEnd": " ou do respectivo terceiro.",
      "signatureFullName": "Assinatura com o nome completo",
      "cpf": "CPF",
      "readContractBeforeAccept": "Leia o contrato até o final antes de aceitar!",
      "checkboxError": "Marque esta caixa se quiser continuar.",
      "fillFieldError": "Por favor preencha este campo.",
      "cpfInvalid": "CPF não é válido."
    }
  }
</i18n>
