import { render, staticRenderFns } from "./DocumentUpload.vue?vue&type=template&id=6e477639"
import script from "./DocumentUpload.vue?vue&type=script&setup=true&lang=ts"
export * from "./DocumentUpload.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./DocumentUpload.vue?vue&type=style&index=0&id=6e477639&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.12_css-loader@6.11.0_webpack@5.95.0__ejs@3.1.1_d306681b7ad9107b62f43640c4bdfc88/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./DocumentUpload.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports