import VueRouter from "vue-router"
import AccountHome from "@/views/account-home/AccountHome.vue"
import ConfirmEBS from "../views/loan-processing/ConfirmEBS.vue"
import ConfirmIdentity from "../views/loan-processing/ConfirmIdentity.vue"
import ConfirmIncome from "../views/loan-processing/ConfirmIncome.vue"
import ConfirmTelephone from "@/views/loan-processing/ConfirmTelephone.vue"
import CurrentLoanDetails from "@/views/account-home/CurrentLoanDetails.vue"
import LoanHistory from "@/views/account-home/LoanHistory.vue"
import LoanProcessing from "../views/loan-processing/LoanProcessing.vue"
import LowerMyPayment from "@/views/account-home/LowerMyPayment.vue"
import PaymentPlanConfirmation from "../views/payment-plans/Confirmation.vue"
import PaymentPlanOffers from "../views/payment-plans/Offers.vue"
import PaymentPlanPaymentConfirmation from "../views/payment-plans/PaymentConfirmation.vue"
import PaymentPlanQuestionnaire from "../views/payment-plans/PaymentPlanQuestionnaire.vue"
import RefinanceDetails from "@/views/account-home/RefinanceDetails.vue"
import SerasaQuestionnairePage from "@/views/loan-processing/SerasaQuestionnairePage.vue"
import WhatsAppConsent from "@/views/account-home/WhatsAppConsent.vue"
import ContractSign from "../views/loan-processing/ContractSign.vue"
import beforeEach, { loggedIn } from "@/router/helpers/beforeEach"
import afterEach from "./helpers/afterEach"
import { VueWithHotjar } from "@/types"
import { externalRoutes } from "@/router/external-routes"
import store, { useAccountStore, useAccountDetailsStore, useCookieStore, usePaymentPlansStore, useLPStore } from "@/store"
import VueI18n from "@/plugins/i18n"
import { logError } from "@/plugins/sentry"
import analytics from "@/lib/analytics"
import { computed } from "vue"
import { nextBusinessDay } from "@/lib/date/helpers"
import { updateActivities } from "@/api/secure/lp-service"
import { AppRouteConfig } from "@/router/meta"
import { customerType } from "@/lib/account/helpers"
import UpdateProfile from "@/views/profile-update/UpdateProfile.vue"

const { analyticDataLayer } = useCookieStore()
const { fullName } = useAccountStore()
const { offers, selectedOffer, showGenericError, confirmed, firstDueDate, fetchOffers } = usePaymentPlansStore()
const { loanApplications, paymentData } = useAccountDetailsStore()
const accountHomeHeadlinePaymentPlanOffers = computed(() => {
  const offersForDisplay = computed(() => store.getters["paymentPlans/offersForDisplay"])

  if (!offersForDisplay.value || offersForDisplay.value.length === 0) {
    return ""
  }

  const offersPluralized = VueI18n.tc("availableOffer", offersForDisplay.value.length)
  return VueI18n.t("accountHomeHeadlinePaymentPlanOffers", {
    n: offersForDisplay.value.length,
    availableOffers: offersPluralized
  })
})

const accountHomeHeadlineDefault = computed(() => {
  if (loanApplications.value?.length) {
    const status = loanApplications.value[0].status
    if (status == "approved" || status == "pending") {
      return VueI18n.t("accountHomeHeadlineApproved")
    } else if (status == "issued" && paymentData.value?.installment_amount == "") {
      return VueI18n.t("accountHomeHeadlineFunded")
    }
  }
  const firstName =  fullName.value === undefined ? "" : ", " + fullName.value!.split(" ")[0]
  return VueI18n.t("accountHomeHeadlineDefault", { firstName: firstName })
})

const routes: Array<AppRouteConfig> = [
  {
    path: "/make-a-payment-plan",
    name: "PaymentPlanQuestionnaire",
    component: PaymentPlanQuestionnaire,
    meta: {
      layoutComponentName: "AccountHomeLayout",
      accountHomeHeadline: "",
      requiresLogin: true
    }
  },
  {
    path: "/payment-plans/offers",
    name: "PaymentPlanOffers",
    component: PaymentPlanOffers,
    meta: {
      layoutComponentName: "AccountHomeLayout",
      accountHomeHeadline: accountHomeHeadlinePaymentPlanOffers,
      requiresLogin: true
    },
    props: true,
    beforeEnter: (to, from, next) => {
      if (!firstDueDate.value) {
        const today = new Date()
        const upcomingBusinessDay = nextBusinessDay(today)
        firstDueDate.value = upcomingBusinessDay.toISOString().slice(0, 10)
      }

      if (offers.value?.length) {
        next()
        return
      }

      fetchOffers(firstDueDate.value, "000").then(() => {
        selectedOffer.value = null
        confirmed.value = false
        next()
      }).catch((e) => {
        const extras = {
          offers: offers.value
        }
        logError(e, extras)
        showGenericError()
      })
    }
  },
  {
    path: "/payment-plans/confirm-payment",
    name: "PaymentPlanPaymentConfirmation",
    component: PaymentPlanPaymentConfirmation,
    meta: {
      layoutComponentName: "AccountHomeLayout",
      accountHomeHeadline: "Detalhes do acordo",
      requiresLogin: true
    },
    props: true,
    beforeEnter: (to, from, next) => {
      if(!selectedOffer.value || confirmed.value) {
        showGenericError()
        next("/loan-status")
        return
      }
      next()
    }
  },
  {
    path: "/payment-plans/boleto-pix-confirmation",
    name: "BoletoAndPixConfirmation",
    component: PaymentPlanConfirmation,
    meta: {
      layoutComponentName: "AccountHomeLayout",
      accountHomeHeadline: "Você escolheu a oferta abaixo e os dados para pagamento encontram-se abaixo",
      requiresLogin: true
    },
    props: true,
    beforeEnter: (to, from, next) => {
      if (!selectedOffer.value || !confirmed.value) {
        showGenericError()
        next("/loan-status")
        return
      }

      next()
    }
  },
  {
    path: "/lp",
    name: "LoanProcessing",
    component: LoanProcessing,
    meta: {
      layoutComponentName: "AccountHomeLayout",
      accountHomeHeadline: "Sua proposta de empréstimo foi pré-aprovada!",
      requiresLogin: true
    },
    beforeEnter:async (to, from, next) => {
      const { analyticDataLayer } = useCookieStore()
      const { activities, getActivities } = useLPStore()
      const { loanApplicationStatus } = useAccountStore()
      const loanNumber = analyticDataLayer.value.loanNumber

      if (loanNumber == null || loanNumber === "") {
        next("/account_home")
        return
      }

      const linkID: string | (string | null)[] = to.query["link"]
      const klaviSuccess: boolean = to.query["klavi_ebs_status"] == "success"
      if (linkID != null || klaviSuccess) {
        await updateActivities(loanNumber, [{ type: "income_verification", "new_status": "in_review" }])
      }

      if(!to.params["skipActivities"]) {
        await getActivities(loanNumber, {})
      }

      if (activities.value.length == 0) {
        next("/account_home")
        return
      }

      const successfulActivities = activities.value.every(a => a.status === "success")
      if (loanApplicationStatus.value == "contract_unsigned" && successfulActivities) {
        next("/contract/new")
      }

      next()
    }
  },
  {
    path: "/lp/ebs",
    name: "ConfirmEBS",
    component: ConfirmEBS,
    meta: {
      layoutComponentName: "AccountHomeLayout",
      accountHomeHeadline: "Comprovação de renda.",
      requiresLogin: true
    },
    beforeEnter: async (to, from, next) => {
      const { analyticDataLayer } = useCookieStore()
      const { getActivities } = useLPStore()
      const loanNumber = analyticDataLayer.value.loanNumber

      if (loanNumber == null || loanNumber === "") {
        next("/account_home")
        return
      }

      await getActivities(loanNumber, {})
      next()
    }
  },
  {
    path: "/lp/identity",
    name: "ConfirmIdentity",
    component: ConfirmIdentity,
    meta: {
      layoutComponentName: "AccountHomeLayout",
      accountHomeHeadline: "Comprovação de identidade",
      requiresLogin: true
    },
    beforeEnter: async (to, from, next) => {
      const { analyticDataLayer } = useCookieStore()
      const { getActivities, activities, fetchDocuments } = useLPStore()
      const { loans } = useAccountDetailsStore()
      const loanNumber = analyticDataLayer.value.loanNumber

      if (loanNumber == null || loanNumber === "") {
        next("/account_home")
        return
      }

      await getActivities(loanNumber, {})
      if(activities.value.length == 0){
        next("/lp")
      }
      await fetchDocuments()

      const type = customerType(loans.value || [])
      const attributes = {
        "customer_type": type,
        "tier": analyticDataLayer.value.tier?.toString(),
        "bank_account_type": analyticDataLayer.value.bank_account_type
      }

      // The experiment key for the 'serasa_identity_validation' experiment is this gibberish
      // because the experiment existed before the cutover from Full Stack experimentation to
      // Feature Experimentation
      if (analytics.plugins.optimizely.decide("bmkrAqhZ8XB8rRdrV6kBzi", attributes) == "serasa") {
        next("/lp/serasa")
      } else {
        next()
      }
    }
  },
  {
    path: "/lp/income",
    name: "ConfirmIncome",
    component: ConfirmIncome,
    meta: {
      layoutComponentName: "AccountHomeLayout",
      accountHomeHeadline: "Comprovação de renda",
      requiresLogin: true
    },
    beforeEnter: async (to, from, next) => {
      const { analyticDataLayer } = useCookieStore()
      const { getActivities, activities, fetchDocuments } = useLPStore()
      const loanNumber = analyticDataLayer.value.loanNumber

      if (loanNumber == null || loanNumber === "") {
        next("/account_home")
        return
      }


      await getActivities(loanNumber, {})
      if(activities.value.length == 0){
        next("/lp")
      }
      await fetchDocuments()

      next()
    }
  },
  {
    path: "/lp/telefone",
    name: "ConfirmTelephone",
    component: ConfirmTelephone,
    meta: {
      layoutComponentName: "AccountHomeLayout",
      accountHomeHeadline: "Valide o seu número de telefone",
      requiresLogin: true
    }
  },
  {
    path: "/lp/doc",
    name: "ConfirmDocuments",
    component: ConfirmIdentity,
    meta: {
      layoutComponentName: "AccountHomeLayout",
      accountHomeHeadline: "Enviar documento.",
      requiresLogin: true
    }
  },
  {
    path: "/lp/serasa",
    name: "ConfirmDocumentsSerasa",
    component: SerasaQuestionnairePage,
    meta: {
      layoutComponentName: "AccountHomeLayout",
      accountHomeHeadline: "Comprovação de identidade",
      requiresLogin: true
    },
    beforeEnter: async (to, from, next) => {
      const { analyticDataLayer } = useCookieStore()
      const { getActivities, activities } = useLPStore()
      const loanNumber = analyticDataLayer.value.loanNumber

      if (!loanNumber) {
        next("/account_home")
        return
      }

      await getActivities(loanNumber, {})
      if(activities.value.length == 0){
        next("/lp")
      }
      next()
    }
  },
  {
    path: "/",
    name: "AccountHome",
    component: AccountHome,
    meta: {
      layoutComponentName: "AccountHomeLayout",
      accountHomeHeadline: accountHomeHeadlineDefault,
      requiresAccountHomeFeatureEnabled: true,
      requiresLogin: true
    },
    beforeEnter: async (to, from, next) => {
      const { accountID } = useAccountStore()
      await useAccountDetailsStore().getDetails(accountID.value.toString())

      next()
    }
  },
  {
    path: "/loan-history",
    name: "LoanHistory",
    component: LoanHistory,
    meta: {
      layoutComponentName: "AccountHomeLayout",
      accountHomeHeadline: "Empréstimos anteriores",
      requiresLogin: true
    },
    beforeEnter: async (to, from, next) => {
      const { accountID } = useAccountStore()
      await useAccountDetailsStore().getDetails(accountID.value.toString())

      next()
    }
  },
  {
    path: "/current-loan",
    name: "CurrentLoan",
    component: CurrentLoanDetails,
    meta: {
      layoutComponentName: "AccountHomeLayout",
      accountHomeHeadline: "Empréstimo atual",
      requiresLogin: true
    },
    beforeEnter: async (to, from, next) => {
      const { accountID } = useAccountStore()
      await useAccountDetailsStore().getDetails(accountID.value.toString())

      next()
    }
  },
  {
    path: "/top-up-refinance",
    name: "TopUpRefinance",
    component: RefinanceDetails,
    props: { useV2Layout: analytics.plugins.optimizely.activate("top_up_refi_layout_web") === "v2" },
    meta: {
      layoutComponentName: "AccountHomeLayout",
      accountHomeHeadline: "Refinanciamento",
      requiresLogin: true
    },
    beforeEnter: async (to, from, next) => {
      const account = useAccountDetailsStore()
      if (account.isEmpty()) {
        const { accountID } = useAccountStore()
        await account.getDetails(accountID.value.toString())
      }

      if (analytics.plugins.optimizely.isFeatureEnabled("simplic_web_top_up_refi")){
        next()
      } else {
        next("/frontend_top_up_refinance")
      }
    }
  },
  {
    path: "/lower-my-payment",
    name: "LowerMyPayment",
    component: LowerMyPayment,
    props: { useV2Layout: analytics.plugins.optimizely.activate("lower_my_payment_layout_web") === "v2" },
    meta: {
      layoutComponentName: "AccountHomeLayout",
      accountHomeHeadline: "Renegociação",
      requiresLogin: true
    },
    beforeEnter: async (to, from, next) => {
      const account = useAccountDetailsStore()
      if (account.isEmpty()) {
        const { accountID } = useAccountStore()
        await account.getDetails(accountID.value.toString())
      }
      const { eligibleProducts } = useAccountDetailsStore()
      const quotes = eligibleProducts.value?.eligible_offers?.lower_my_payment?.quotes
      if (!quotes) {
        next("/account_home")
      }
      next()
    }
  },
  {
    path: "/atualizar_dados",
    name: "UpdateProfile",
    component: UpdateProfile,
    meta: {
      layoutComponentName: "UpdateProfile",
      accountHomeHeadline: "Atualizar Dados",
      requiresLogin: true
    },
    beforeEnter: async (to, from, next) => {
      const account = useAccountDetailsStore()
      if (account.isEmpty()) {
        const { accountID } = useAccountStore()
        await account.getDetails(accountID.value.toString())
      }

      next()
    }
  },
  {
    path: "/consents/whatsapp/:token?",
    name: "WhatsAppConsent",
    component: WhatsAppConsent,
    meta: {
      layoutComponentName: "AccountHomeLayout",
      accountHomeHeadline: "Consentimento do WhatsApp",
      requiresLogin: false
    },
    beforeEnter: async (to, from, next) => {
      if (!to.params.token && !loggedIn()) {
        next("/login?redirect_to=/consents/whatsapp")
      }
      next()
    }
  },
  {
    path: "/contract/new",
    name: "ContractSign",
    component: ContractSign,
    meta: {
      layoutComponentName: "AccountHomeLayout",
      accountHomeHeadline: "",
      requiresLogin: true
    },
    beforeEnter: async (to, from, next) => {
      const { analyticDataLayer } = useCookieStore()
      const { unsignedContract, getContract } = useLPStore()
      const accountID = analyticDataLayer.value.customerId

      if (accountID == null || accountID === "") {
        next("/account_home")
        return
      }

      await getContract()

      if (unsignedContract.value == null) {
        next("/account_home")
        return
      }

      next()
    }
  },
  ...Object.values(externalRoutes) // prepend external routes
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => beforeEach(to, from, next))
router.afterEach((to, from) => afterEach((router.app as VueWithHotjar), to, from, analyticDataLayer.value.customerId, analyticDataLayer.value.loanId))

export default router
export * from "./external-routes"
