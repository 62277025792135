<script setup lang="ts">
import { withDefaults, defineProps, defineEmits } from "vue"

type ContractErrorDialogProps = {
  showDialog: boolean;
}

const props = withDefaults(defineProps<ContractErrorDialogProps>(), {})

const emit = defineEmits<{
  (event: "close"): void
}>()

const closeDialog = function() {
  emit("close")
}
</script>
<template>
  <div v-if="props.showDialog" class="dialog-box">
    <p>{{ $t("message") }}</p>
    <div class="button-container">
      <button type="button" @click="closeDialog">
        <span>{{ $t("tryAgain") }}</span>
      </button>
    </div>
  </div>
</template>
<style scoped lang="scss">
.dialog-box {
  z-index: 1000;
  border: 4px solid $simplic-yellow;
  text-align: center;
  width: 50%;
  background-color: $simplic-background;

  p {
      padding: 20px 10px 5px;
      font-size: 18px;
  }

  .button-container {
    width: 50%;
    padding-bottom: 10px;
    margin: 0 auto;

    button {
      background-color: $simplic-yellow;
      padding: 10px;
      width: 100%;
      border-radius: 5px;

      span {
        color: $simplic-white;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 1px;
      }
    }
  }

  @media (max-width: 500px) {
    p {
      font-size: 14px;
    }

    .button-container {
      width: 80%;

      button {
        span {
          font-size: 13px;
        }
      }
    }
  }
}
</style>
<i18n>
  {
    "pt": {
      "message": "Ocorreu um erro na assinatura do contrato.",
      "tryAgain": "Tentar novamente"
    }
  }
</i18n>
