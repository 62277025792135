import { render, staticRenderFns } from "./OffersTooltip.vue?vue&type=template&id=2703395b&scoped=true"
var script = {}
import style0 from "./OffersTooltip.vue?vue&type=style&index=0&id=2703395b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.12_css-loader@6.11.0_webpack@5.95.0__ejs@3.1.1_d306681b7ad9107b62f43640c4bdfc88/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2703395b",
  null
  
)

export default component.exports