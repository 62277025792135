<script setup lang="ts">
import { UnsignedContract } from "@/api/secure"

type ContractLoanSummaryProps = {
  unsignedContract: UnsignedContract;
}

const props = withDefaults(defineProps<ContractLoanSummaryProps>(), {})

const formatCurrency = function(amount: string) {
  const amountToNumber = parseFloat(amount)
  return amountToNumber.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
}
</script>
<template>
  <div class="center-grid">
    <div class="summary-row">
      <div class="col">
        <p class="big-green">{{ formatCurrency(props.unsignedContract.loan_agreement.amount) }}</p>
        <p>{{ $t("approvedLoanAmount") }}</p>
      </div>
      <div class="col">
        <p class="big-green">{{ props.unsignedContract.installments_count }}</p>
        <p>{{ $t("numInstallments") }}</p>
      </div>
      <div class="col last">
        <p class="big-green">{{ formatCurrency(props.unsignedContract.installment_payment_amount) }}</p>
        <p>{{ $t("installmentValueTable") }}</p>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
p {
  font-size: 16px;
}

.center-grid {
  display: grid;
  place-items: center;
  padding-top: 1em;
  padding-bottom: 1em;
}

.summary-row {
  border: 1px solid $simplic-yellow;
  padding: 0.5em;
  background: #fafafa;
  border-radius: 0.5em;
  margin-bottom: 0.5em;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  margin: -12px;
}

.col {
  flex: 1;
  padding: 1em 0.25em 0 0.25em;
  margin: 1em;
  text-align: center;

    @media (max-width: 500px) {
        margin-top: 1px;
    }
}

.col:not(:first-child) {
    border-left: 0.05em solid $simplic-dark-text;
    margin-top: 1em;
    padding-left: 2em;
    @media (max-width: 500px) {
        border-top: 0.05em solid $simplic-dark-text;
        border-left: none;
        margin-top: 0;
        padding-left: 0;
    }
}

@media (max-width: 500px) {
    .last {
        margin-bottom: 4px;
    }
}

.big-green {
  color: $simplic-green;
  line-height: 1em;
  font-size: 2.5em;
  margin-bottom: 0.25em;
  font-family: "Bree Serif", serif;
}
</style>
<i18n>
    {
        "pt": {
            "approvedLoanAmount": "Valor do empréstimo aprovado",
            "numInstallments": "Número de parcelas",
            "installmentValueTable": "Valor da parcela"
        }
    }
</i18n>
