import { render, staticRenderFns } from "./ExpressiFrame.vue?vue&type=template&id=3c79723e&scoped=true"
import script from "./ExpressiFrame.vue?vue&type=script&lang=ts"
export * from "./ExpressiFrame.vue?vue&type=script&lang=ts"
import style0 from "./ExpressiFrame.vue?vue&type=style&index=0&id=3c79723e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.12_css-loader@6.11.0_webpack@5.95.0__ejs@3.1.1_d306681b7ad9107b62f43640c4bdfc88/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c79723e",
  null
  
)

export default component.exports