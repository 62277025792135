import { buildHelpers } from "@/store/composition-helpers"
import store, { State, useCookieStore } from "@/store"
import { WritableComputedRef } from "vue"
import { updateField, getField } from "vuex-map-fields"
import { Module } from "vuex"
import { logError } from "@/plugins/sentry"
import {
  getLPActivities,
  LPActivityData,
  LPActivities,
  RetrievedDocument,
  getDocuments,
  uploadDocument,
  optInToDirectDebit,
  OfferData,
  getUnsignedContract,
  UnsignedContract
} from "@/api/secure/lp-service"

const allowedActivities = ["phone_verification", "identity_verification", "income_verification"]

const { mapGettersAndSettersToComputed } = buildHelpers<LPStore>(
  () => store, "lp"
)

interface LPStore {
  activities: LPActivityData[];
  documents: Map<string, RetrievedDocument[]>;
  lpType: string;
  directDebitOptIn: null|boolean;
  primaryOffer: null|OfferData;
  secondaryOffer: null|OfferData;
  unsignedContract: null|UnsignedContract;
}

export const newLPState = (): LPStore => {
  return {
    activities: [],
    documents: new Map<string, RetrievedDocument[]>(),
    lpType: "",
    directDebitOptIn: null,
    primaryOffer: null,
    secondaryOffer: null,
    unsignedContract: null
  }
}
const lpState: LPStore = newLPState()

interface LPAccessors {
  activities: WritableComputedRef<LPActivityData[]>;
  documents: WritableComputedRef<Map<string, RetrievedDocument[]>>;
  lpType: WritableComputedRef<string>;
  directDebitOptIn: WritableComputedRef<null|boolean>;
  primaryOffer: WritableComputedRef<OfferData>;
  secondaryOffer: WritableComputedRef<OfferData>;
  unsignedContract: WritableComputedRef<UnsignedContract>;
  getActivities: (loanId: string, params: Record<string, unknown>) => Promise<void>;
  compare: (a: RetrievedDocument, b: RetrievedDocument) => number;
  categorizeDocuments: (documents: RetrievedDocument[]) => void;
  sortDocuments: () => void;
  fetchDocuments: () => Promise<void>;
  addDocumentToActivity: (file: File, documentType: string) => Promise<void>;
  sendOptInRequest: () => Promise<void>;
  getContract: () => Promise<void>;
}

function useLPStore(): LPAccessors {
  const mapFields = mapGettersAndSettersToComputed(Object.keys(lpState) as Array<keyof LPStore>)
  const { activities, documents, lpType, directDebitOptIn, primaryOffer, secondaryOffer, unsignedContract } = mapFields
  const { analyticDataLayer } = useCookieStore()

  const getActivities = async (loanId: string, params: Record<string, unknown>) => {
    try {
      const activityData: LPActivities = await getLPActivities(loanId, params)
      activities.value = activityData.lp_activities.filter((activity) => allowedActivities.includes(activity.name))
      lpType.value = activityData.lp_type
      directDebitOptIn.value = activityData.direct_debit_opt_in
      primaryOffer.value = activityData.primary_offer
      secondaryOffer.value = activityData.secondary_offer
    } catch(e) {
      logError(e)
      return
    }
  }

  const compare = (a: RetrievedDocument, b: RetrievedDocument): number => {
    if(a.createdAt < b.createdAt) {
      return 1
    }
    if(a.createdAt > b.createdAt) {
      return -1
    }
    return 0
  }

  const categorizeDocuments = (inputDocuments: RetrievedDocument[]) => {
    const map = new Map<string, RetrievedDocument[]>()
    for(let i = 0; i < inputDocuments.length; i++) {
      const documentType = inputDocuments[i].docType
      let documents = map.get(documentType)
      if (!documents) {
        documents = []
      }
      documents.push(inputDocuments[i])
      map.set(documentType, documents)
    }
    documents.value = map
  }

  const sortDocuments = () => {
    documents.value.forEach((value: RetrievedDocument[]) => {
      value.sort(compare)
    })
  }

  const fetchDocuments = async () => {
    try {
      const loanApplicationID = analyticDataLayer.value.loan_application_id || ""
      const response: RetrievedDocument[] = await getDocuments(loanApplicationID)
      categorizeDocuments(response)
      sortDocuments()
    } catch(e) {
      logError(e)
    }
  }

  const addDocumentToActivity = async (file: File, documentType: string) => {
    const loanApplicationID = analyticDataLayer.value.loan_application_id || ""
    try {
      await uploadDocument([file], documentType, loanApplicationID)
      fetchDocuments()
    } catch(e) {
      logError(e)
    }
  }

  const sendOptInRequest = async () => {
    const loanApplicationID = analyticDataLayer.value.loan_application_id || ""
    try {
      await optInToDirectDebit(loanApplicationID)
    } catch(e) {
      logError(e)
    }
  }

  const getContract = async () => {
    const id = analyticDataLayer.value.customerId || ""
    try {
      const contract: UnsignedContract = await getUnsignedContract(id)
      unsignedContract.value = contract
    } catch(e) {
      logError(e)
      return
    }
  }

  return {
    ...mapFields,
    getActivities,
    compare,
    categorizeDocuments,
    sortDocuments,
    fetchDocuments,
    addDocumentToActivity,
    sendOptInRequest,
    getContract
  } as LPAccessors
}

const lp: Module<LPStore, State> = {
  namespaced: true,
  state: lpState,
  getters: { getField },
  mutations: { updateField }
}

export default lp
export { lpState, useLPStore, LPStore, LPAccessors }
