import { client } from "@/api/secure-service"
import { useCookieStore } from "@/store"

export interface LPActivities {
  lp_activities: LPActivityData[];
  direct_debit_opt_in: null|boolean;
  primary_offer: null|OfferData;
  secondary_offer: null|OfferData;
  lp_type: string;
}

export interface LPActivityData {
  name: string;
  status: string;
  reason: null|string;
}

export interface Document {
  account_id: number;
  document_type: string;
  file: string;
  file_name: string;
  file_type: string;
}

export interface DocumentUploadResponse {
  status: boolean;
}

export interface DirectDebitOptInResponse {
  status: string;
}

export interface OfferData {
  account_id: number;
  annual_interest_rate: string;
  draw_fee_percent: string;
  expired: boolean;
  expires_at: null|string;
  finance_charge: null|number;
  first_installments_amount: string;
  is_consolation: boolean;
  late_fee_days: null|number;
  loan_length: null|number;
  loan_offer_id: number;
  loan_to_payment_multiplier: string;
  max_amount: string;
  min_amount: string;
  num_installments: number;
  origination_fee_percent: string;
  product: string;
  reasons_unsuitable: null|string;
  statement_offset: number;
  suitable: boolean;
  target_loan_amount: string;
  total_cso_fee: null|string;
  total_repayment_amount: null|string;
}

export interface File extends Blob {
  readonly name: string;
  lastModified: number;
}

export interface RetrievedDocument {
  uuid: string;
  associationID: string;
  associationType: string;
  brand: string;
  channel: string;
  context: string;
  mimeType: string;
  docType: string;
  fileName: string;
  description: string;
  meta: JSON;
  url: string;
  createdAt: string;
  updatedAt: string;
}

export interface ActivityUpdatePayload {
  type: string;
  new_status: string;
}

export interface ActivityUpdateResponse {
  count: number;
}

export interface SerasaQuestionnaireUploadRequest {
  questionnaire: SerasaQuestionnaire;
  accountID: string;
}

export interface SerasaQuestionnaire {
	id: string;
	quizRequest: SerasaQuizRequest;
	questions: SerasaQuestion[];
	answered: boolean;
	availableOn: string;
	answeredOn: string;
	email: string;
	scoreRange: SerasaScoreRange;
	errorResponse: SerasaErrorResponse;
}

export interface SerasaQuizRequest {
	document: string;
	policyId?: string;
	customId?: string;
}

export interface SerasaQuestion {
	id: string;
	description: string;
	answers: SerasaAnswer[];
	assessment: number;
}

export interface SerasaAnswer {
	id: string;
	description: string;
	selected: boolean;
}

export interface SerasaScoreRange {
	id: string;
	initValue: number;
	endValue: number;
	description: string;
	score: number;
}

export interface SerasaErrorResponse {
	timestamp: string;
	status: string;
	message: string;
	path: string;
	code: number;
}

export interface Locale {
  current: string;
  available: string[];
}

export interface Summary {
  loan_number: string;
  loan_amount: string;
  disbursement_amount: string;
  disbursement_date: string;
  average_monthly_interest_rate: string;
  total_repayment_amount: string;
  recurring_payment_amount: string;
  total_payments: string;
  first_payment_due_date: string;
  last_payment_due_date: string;
  contract_type: string;
  apr: null|number;
}

export interface Contract {
  contract_id: null|string;
  template_type: string;
  locale: Locale;
  customer_signature: null|string;
  brand_signature: string;
  body: string;
  s3_url: null|string;
  aux_contracts: null|JSON;
  page_order: null|string;
  summary: Summary;
  contract_is_stale: boolean;
}

export interface LoanAgreement {
  account_id: number;
  amount: string;
  annual_interest_rate: string;
  apr: null|JSON;
  cash_out_disbursement_date: string;
  contract_id: null|number;
  contractual_payment_count: number;
  created_at: string;
  disbursement_amount: string;
  draw_fee_percent: string;
  earliest_disbursement_date: string;
  fee_servicing_rule: null|JSON;
  finance_charge_start_date: null|JSON;
  funding_method: string;
  lastest_disbursement_date: string;
  lender: string;
  loan_agreement_id: number;
  loan_application_id: number;
  loan_length: null|JSON;
  loan_number: string;
  loan_offer_id: number;
  product: string;
  prospect_id: null|JSON;
  recurring_payment_amount: null|JSON;
  refinance_amount: string;
  repayment_method: string;
  updated_at: string;
  contract: Contract;
}

export interface UnsignedContract {
  loan_agreement: LoanAgreement;
  installments_count: number;
  installment_payment_amount: string;
}

export interface SignContractErrors {
  errors: any|null;
}

export interface SignContractResponse {
  body: SignContractErrors|null;
}

export interface ContractSignatureParams {
  agreement_a: string;
  agreement_c: string;
  cpf: string;
  full_name: string;
  read_contract: string;
  locale: string;
  loan_application_id: number;
  contract_id: string;
}

export async function getLPActivities (loanNumber: string, params: object): Promise<LPActivities> {
  try {
    const response = await client().get<LPActivities>(`/loan-processing/loans/${loanNumber}/lp-activities`, undefined, { params: params })

    return response as LPActivities
  } catch(err) {
    return Promise.reject(err)
  }
}

export function fileName(uploadedFile: File): string {
  return uploadedFile && uploadedFile.name
}

export function fileType(uploadedFile: File): string {
  return uploadedFile && (fileName(uploadedFile).split(".").pop() || "")
}

export async function documentToUpload(uploadedFile: File, documentType: string, loanApplicationID: string): Promise<Document> {
  const attrs = {
    account_id: useCookieStore().accessTokensData.value.account_id,
    loan_application_id: loanApplicationID,
    document_type: documentType,
    file: "",
    file_name: fileName(uploadedFile),
    file_type: fileType(uploadedFile)
  }

  return new Promise((resolve) => {
    const fr = new FileReader()
    fr.onload = (e) => {
      if (e.target && e.target.result) {
        const b64 = e.target.result.toString().split(",")
        attrs.file = b64 && b64[1]
      }
      resolve(attrs)
    }
    fr.readAsDataURL(uploadedFile)
  })
}

export async function uploadDocument (uploadedFiles: File[], documentType: string, loanApplicationID: string): Promise<DocumentUploadResponse> {
  try {
    let response
    for (const uploadedFile of uploadedFiles) {
      response = await client().post("/loan-processing/document-upload", await documentToUpload(uploadedFile, documentType, loanApplicationID))
    }
    const uploadResponse = response as DocumentUploadResponse

    return Promise.resolve(uploadResponse)
  } catch(err) {
    return Promise.reject(err)
  }
}

export async function getDocuments (loanApplicationID: string): Promise<RetrievedDocument[]> {
  try {
    const response = await client().get<RetrievedDocument[]>(`/loan-processing/documents/${loanApplicationID}`)

    return response as RetrievedDocument[]
  } catch(err) {
    return Promise.reject(err)
  }
}

export async function optInToDirectDebit (loanApplicationID: string): Promise<DirectDebitOptInResponse> {
  try {
    const payload = {
      "direct_debit_opt_in": true,
      "overdraft_opt_in": true
    }

    const response = await client().post(`/loan-processing/loan-application/${loanApplicationID}/opt-in`, payload)

    return Promise.resolve(response as DirectDebitOptInResponse)
  } catch(err) {
    return Promise.reject(err)
  }
}

export async function updateActivities(loanNumber: string, activities: ActivityUpdatePayload[]): Promise<ActivityUpdateResponse> {
  try {
    const payload = {
      "loan_number": loanNumber,
      "activities": activities
    }

    const response = await client().post("/loan-processing/activities/update", payload)

    return Promise.resolve(response as ActivityUpdateResponse)
  } catch (err) {
    return Promise.reject(err)
  }
}

export async function getSerasaQuestionnaire (cpf: string): Promise<SerasaQuestionnaire> {
  try {
    const response = await client().get("/loan-processing/vendors/serasa/questionnaire?cpf=" + cpf)

    return Promise.resolve(response as SerasaQuestionnaire)
  } catch (err) {
    return Promise.reject(err)
  }
}

export async function postSerasaQuestionnaire (payload: SerasaQuestionnaireUploadRequest): Promise<SerasaScoreRange> {
  try {
    const response = await client().post("/loan-processing/vendors/serasa/questionnaire", payload)

    return Promise.resolve(response as SerasaScoreRange)
  } catch (err) {
    return Promise.reject(err)
  }
}

export async function getUnsignedContract (accountID: string): Promise<UnsignedContract> {
  try {
    const response = await client().get(`/loan-processing/accounts/${accountID}/contract`)

    return response as UnsignedContract
  } catch (err) {
    return Promise.reject(err)
  }
}

export async function postSignContract (accountID: string, loanNumber: string, payload: ContractSignatureParams): Promise<SignContractResponse> {
  try {
    const response = await client().post(`/loan-processing/accounts/${accountID}/loan_agreements/${loanNumber}/contract/sign-post-lp`, payload)

    return response as SignContractResponse
  } catch (err) {
    return Promise.reject(err)
  }
}
